import React from "react";
import LoginField from "../components/LoginField";

function Login() {
  return (
    <main>
      <LoginField />
    </main>
  );
}

export default Login;
