import React from "react";
import RegistrationField from "../components/RegistrationField";

function register() {
  return (
    <main>
      <RegistrationField />
    </main>
  );
}

export default register;
